import { useDropzone, DropzoneOptions } from 'react-dropzone';
import {
  Avatar,
  Box,
  Button,
  HStack,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';

export default function ImageUpload() {
  const [image, setImage] = useState<File>();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': ['.jpg', '.jpeg', '.png'],
    },
    onDrop: (acceptedFiles) => {
      const newImg = acceptedFiles[0];
      setImage(newImg);
      // Notify the parent component
    },
  });

  return (
    <HStack
      {...getRootProps()}
      p={4}
      spacing={8}
      border="2px dashed"
      borderColor={isDragActive ? 'teal' : 'teal20per'}
      _hover={{ borderColor: 'teal40per', cursor: 'pointer' }}
      borderRadius="lg"
      h={60}
    >
      <input {...getInputProps()} />
      <Avatar
        h={200}
        w={200}
        borderRadius="lg"
        src={image && URL.createObjectURL(image)}
      />
      <VStack
        spacing={4}
        align="flex-start"
        h="full"
        justify="center"
        borderRadius="md"
        bg="light"
      >
        <Button variant="teal">Upload Image</Button>
        <Box>
          {isDragActive ? (
            <Text color="tealLight">Release to upload the image</Text>
          ) : (
            <Text color="tealLight">Or drop the image here</Text>
          )}
          <Text color="tealLight" fontSize="xs">
            supported formats: *.jpg, *.jpeg, *.png
          </Text>
        </Box>
      </VStack>
    </HStack>
  );
}
