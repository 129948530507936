import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/dashboard.page';
import Lenders from './pages/lenders.page';
import Pipeline from './pages/pipeline.page';
import Property from './pages/property.page';
import Templates from './pages/templates.page';
import Settings from './pages/settings.page';
import AuthRedirect from './helpers/authRedirect.helper';
import { Login } from './pages/login.page';
import MainLayout from './components/mainLayout.component';
import { Signup } from './pages/signup.page';

function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/authRedirect" element={<AuthRedirect />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />

        {/* Main App Routes */}
        <Route path="/" element={<MainLayout />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/pipeline" element={<Pipeline />} />
          <Route path="/lenders" element={<Lenders />} />
          <Route path="/templates" element={<Templates />} />
          <Route path="/property/:propertyId" element={<Property />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
