import {
  Box,
  BoxProps,
  CloseButton,
  Flex,
  FlexProps,
  Link,
  useDisclosure,
  VStack,
  Text,
  Heading,
  Drawer,
  DrawerContent,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  HStack,
  Avatar,
  IconButton,
} from '@chakra-ui/react';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DashboardIcon } from '../assets/icons/dashboard.icon';
import { DropdownIcon } from '../assets/icons/dropdown.icon';
import { LenderIcon } from '../assets/icons/lender.icon';
import { PipelineIcon } from '../assets/icons/pipeline.icon';
import { TemplatesIcon } from '../assets/icons/templates.icon';
// @ts-ignore
import logo from '../assets/nectorLogo.png';
import { MenuIcon } from '../assets/icons/menu.icon';
import { useUser } from '../providers/user.provider';
import { useCompany } from '../providers/company.provider';
import { UserSVC } from '../services/user.service';

/* ––– Constants ––– */
type PageState =
  | 'Dashboard'
  | 'Pipeline'
  | 'Templates'
  | 'Lenders'
  | 'Property'
  | 'Settings';

interface LinkItemProps {
  name: string;
  icon: any;
  navLink: string;
}
const LinkItems: Array<LinkItemProps> = [
  {
    name: 'Dashboard',
    icon: <DashboardIcon />,
    navLink: '/',
  },
  {
    name: 'Pipeline',
    icon: <PipelineIcon />,
    navLink: '/pipeline',
  },
  {
    name: 'Lenders',
    icon: <LenderIcon />,
    navLink: '/lenders',
  },
  {
    name: 'Templates',
    icon: <TemplatesIcon />,
    navLink: '/templates',
  },
];

interface NavItemProps extends FlexProps {
  icon: any;
  children: string;
  navLink: string;
  active: boolean;
}
const NavItem = ({ icon, children, navLink, active }: NavItemProps) => {
  return (
    <Link href={navLink} style={{ textDecoration: 'none' }}>
      <HStack
        py="1.5"
        px={4}
        mx={8}
        mb={2}
        borderRadius="lg"
        role="group"
        cursor="pointer"
        color={'white'}
        bg={active ? 'green' : 'transparent'}
        _hover={{
          boxShadow: active ? 'none' : '0 0 0 3px rgba(0,0,0,0.20)',
        }}
      >
        {icon}
        <Box ml="4">
          <Text variant="b2_bold">{children}</Text>
        </Box>
      </HStack>
    </Link>
  );
};

interface SideBarProps extends BoxProps {
  onClose: () => void;
  page: string;
}

const SideBar = ({ onClose, page, ...rest }: SideBarProps) => {
  const navigate = useNavigate();
  const { company } = useCompany();
  return (
    <Box
      transition="3s ease"
      borderRight="3px solid"
      borderColor="green"
      w={{ base: 'full', lg: '280px' }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <VStack align="left" justify="space-between" h="full">
        <Box>
          <Flex
            alignItems="center"
            mx={8}
            h={12}
            my={8}
            justifyContent="space-between"
          >
            <Menu variant={'inNav'}>
              <MenuButton
                cursor={'pointer'}
                bg={'teal40per'}
                p={2}
                borderRadius={'lg'}
                border={'3px solid transparent'}
                _hover={{
                  bg: 'teal20per',
                  borderColor: 'teal60per',
                }}
              >
                <HStack justify={'space-between'}>
                  <Box px={2} textAlign="left">
                    <Heading variant="h5">{company?.name}</Heading>
                  </Box>
                  <Box display={{ base: 'none', lg: 'flex' }}>
                    <DropdownIcon />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList>
                <MenuItem>Company 1</MenuItem>
                <MenuItem>Company 2</MenuItem>
                <MenuItem>Company 3</MenuItem>
                <MenuItem>+ Add a company</MenuItem>
              </MenuList>
            </Menu>
            <CloseButton
              display={{ base: 'flex', lg: 'none' }}
              onClick={onClose}
            />
          </Flex>
          <Box py={4}>
            {LinkItems.map((link) => (
              <NavItem
                key={link.name}
                icon={link.icon}
                navLink={link.navLink}
                active={page === link.name ? true : false}
              >
                {link.name}
              </NavItem>
            ))}
          </Box>
        </Box>
        <Box mx={8} my={8} as={'button'} onClick={() => navigate('/')}>
          <img src={logo} alt={'Nector'} style={{ height: '40px' }} />
        </Box>
      </VStack>
    </Box>
  );
};

interface TopBarProps extends FlexProps {
  onOpen: () => void;
  page: string;
}

const TopBar = ({ onOpen, page, ...rest }: TopBarProps) => {
  const { user } = useUser();

  const handleSignOut = () => {
    UserSVC.logout();
  };
  return (
    <Flex
      ml={{ base: 0, lg: '280px' }}
      px={{ base: 2, lg: 12 }}
      pt={8}
      pb={6}
      width={{ base: 'full', lg: 'calc(100% - 260px)' }}
      alignItems="center"
      sx={{ backdropFilter: 'blur(8px)' }}
      justifyContent="space-between"
      position="fixed"
      zIndex="overlay"
    >
      <HStack>
        <IconButton
          display={{ base: 'flex', lg: 'none' }}
          onClick={onOpen}
          aria-label="open menu"
          icon={<MenuIcon color="black" />}
        />
        <Heading variant={'h2'}>{page}</Heading>
      </HStack>

      <Menu>
        <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
          <HStack>
            <Avatar size={'sm'} src={user?.avatar} />
            <Box px={2} textAlign="left">
              <Text variant="b2_bold">{user?.first_name}</Text>
              <Text variant="b3" opacity={0.6}>
                Admin
              </Text>
            </Box>
            <Box display={{ base: 'none', lg: 'flex' }}>
              <DropdownIcon />
            </Box>
          </HStack>
        </MenuButton>
        <MenuList>
          <MenuItem as="a" href={'/settings'}>
            Settings
          </MenuItem>
          <MenuItem onClick={() => handleSignOut()}>Sign out</MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};

/* ––– Nav function ––– */
function Nav({ children }: { children: ReactNode }) {
  const { company, refetchCompany } = useCompany();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const [pageState, setPageState] = useState<PageState | ''>('');

  useEffect(() => {
    if (company === undefined) refetchCompany();
  }, [company]);

  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('access_token') !== null) {
      setIsAuth(true);
    }
  }, [isAuth]);

  useEffect(() => {
    if (location.pathname.includes('/settings')) {
      setPageState('Settings');
    } else if (location.pathname.includes('/templates')) {
      setPageState('Templates');
    } else if (location.pathname.includes('/pipeline')) {
      setPageState('Pipeline');
    } else if (location.pathname.includes('/lenders')) {
      setPageState('Lenders');
    } else if (location.pathname.includes('/property')) {
      setPageState('Dashboard');
    } else {
      setPageState('Dashboard');
    }
  }, [location]);

  return (
    <Box
      minH="100vh"
      bg="linear-gradient(103deg, #073B3A 8.46%, #03030A 73.12%, #000 100%)"
    >
      <SideBar
        onClose={() => onClose}
        page={pageState}
        display={{ base: 'none', lg: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent bg={'teal60per'} backdropFilter="blur(8px)">
          <SideBar onClose={onClose} page={pageState} />
        </DrawerContent>
      </Drawer>
      <TopBar onOpen={onOpen} page={pageState}></TopBar>
      <Box ml={{ base: 0, lg: '280px' }} pt={32} minH={'100vh'}>
        {children}
      </Box>
    </Box>
  );
}

export default Nav;
