import { createContext, useContext, useEffect, useState } from 'react';
import { User } from '../types/user.type';
import { UserSVC } from '../services/user.service';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

type UserContextType = {
  user: User | undefined;
};

export const UserContext = createContext<UserContextType>({
  user: undefined,
});

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  /** ––– How it works –––
   * - user logs in (may happen in sepearate app)
   * - check for tokens in local storage
   * - if token, decode and get user
   * - if no token, redirect to login
   */

  const navigate = useNavigate();
  const token = localStorage.getItem('access_token');
  // console.log('» token', token);

  const [user, setUser] = useState<User | undefined>();

  const [hasFetched, setHasFetched] = useState<boolean>(false);

  useEffect(() => {
    const checkToken = async () => {
      if (token === null) {
        navigate('/login');
      } else {
        const decoded = jwtDecode(token);
        const exp = decoded.exp || 1; // Adjust according to your token's payload structure
        if (Date.now() >= exp * 1000) {
          navigate('/login');
        }
      }
    };
    checkToken();
  }, [token, navigate]);

  // Get user from token
  useEffect(() => {
    const decodeAndSetUser = async (token: string) => {
      try {
        const res = await UserSVC.getOne(token);
        setUser(res);
        setHasFetched(true);
      } catch (e) {
        console.log('not auth');
      }
    };

    const token = localStorage.getItem('access_token');
    if (token && !hasFetched) {
      decodeAndSetUser(token);
    }
  }, [hasFetched, user]);

  // const fakeUser: User = {
  //   id: '00001',
  //   first_name: 'Gary',
  //   last_name: 'Gibson',
  //   email: "gary@gibson.com",
  //   username: "gary@gibson.com",
  //   date_joined: new Date("2024-01-01"),
  //   avatar:
  //     'https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=2960&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  //   companies: [
  //     {
  //       id: '00001',
  //       role: 'Admin',
  //     },
  //   ],
  // };

  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
