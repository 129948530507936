import axios from 'axios';
import { http } from '../httpCommon';
import { User } from '../types/user.type';

/** ––– Create User –––
 * - provide basic info needed to set up a profile (name, email, password)
 * - post a new User into the database
 */
const create = (data: User) => {
  try {
    return http.post('/create-user', data);
  } catch (err) {
    console.log(err);
  }
};

/** ––– Get Authed User –––
 * - return user profile data to display if they are authorized
 */
const getOne = async (token: string | null) => {
  try {
    const res = await http.get(`/user`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const resUser: User = res.data;
    return resUser;
  } catch (err) {
    console.log(err);
  }
};

/** ––– Update User –––
 * - provide new info to change or add to the existing user object
 * - post changes into the database
 */
const update = async (data: any, token: string) => {
  try {
    const res = await http.put(`/update-user`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

const logout = async () => {
  try {
    // const { data } = await http.post('/logout/', {
    //   refresh_token: localStorage.getItem('refresh_token'),
    // });
    // console.log('logout', data);
    localStorage.clear();
    axios.defaults.headers.common['Authorization'] = null;
    window.location.href = '/login';
  } catch (e) {
    console.log('logout not working', e);
  }
};

export const UserSVC = {
  create,
  getOne,
  update,
  logout,
};
