import { ComponentStyleConfig } from '@chakra-ui/react';

export const SelectStyles: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: {
    field: {},
    icon: {
      fontSize: '2xl',
    },
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {
    sm: {
      field: {
        borderRadius: 'lg',
      },
    },
  },
  // styles for different visual variants ("outline", "solid")
  variants: {
    outline: {
      field: {
        color: 'white',
        border: '2px solid',
        borderColor: 'teal40per',
        _placeholder: {
          color: 'white60per',
        },
        _hover: {
          borderColor: 'teal60per',
          _focus: {
            borderColor: 'tealLight',
            color: 'tealLight',
          },
        },
        _focusVisible: {
          color: 'tealLight',
          borderColor: 'tealLight',
          boxShadow: `0px 0px 0px 1px #AFFFFD`,
        },
      },
      addon: {
        borderBottom: '2px solid',
        borderColor: 'green',
        borderRadius: '0',
        px: '0',
        bg: 'transparent',
      },
    },
  },
};
