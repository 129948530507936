// TODO: implement fetching templates from the server
import {
  Box,
  Container,
  Heading,
  HStack,
  VStack,
  Text,
} from '@chakra-ui/react';
import { Template } from '../types/template.type';
import { TemplateSVC } from '../services/template.service';

import AddTemplate from '../components/modals/addTemplate.modal';
import { useEffect, useState } from 'react';
// import { useCompany } from '../providers/company.provider';
// import { TemplateSVC } from '../services/template.service';

const TemplateCell = ({ template }: { template: Template }) => {
  return (
    
    <Box
      bg={'teal40per'}
      borderRadius={'lg'}
      border={'3px solid'}
      borderColor={'transparent'}
      w={'full'}
      p={4}
      _hover={{
        borderColor: 'teal60per',
        bg: 'teal20per',
        cursor: 'pointer',
      }}
      onClick={() => null}
    >
      <a href={template.file}>
        <Heading variant={'h5'}>{template.name}</Heading>
      </a>
    </Box>
    
  );
};

const templateDemo: Template[] = [
  {
    id: 'n0',
    name: '(Nector) Amoritization Schedule Calculator',
    file: 'https://filestosave.s3.amazonaws.com/Amortization+Schedule+Calculator.xlsx',
  },
  {
    id: 'n1',
    name: '(Nector) Personal Financial Statement',
    file: 'https://filestosave.s3.amazonaws.com/Nector+Personal+Financial+Statement+Template.xlsx',
  },
  {
    id: 'n2',
    name: '(Nector) Proforma and Calculations',
    file: 'https://filestosave.s3.amazonaws.com/Nector+Proforma+and+Calculations.xlsx',
  },
];

function Templates() {
  const [openAddTemplate, setOpenAddTemplate] = useState(false);
  const [templates, setTemplates] = useState<Template[]>([]);
  
  // const { company } = useCompany();

  useEffect(() => {
    const fetchTemplates = async () => {
      // fetch templates
      try {
        
        const token = localStorage.getItem('access_token') || "";
        const res = await TemplateSVC.getByCompany(token);
        setTemplates(res || []);
        
      } catch (err) {
        console.error(err);
      }
    }

    fetchTemplates();

  }, []);
  return (
    <>
      <VStack>
        <Container maxW="860">
          <VStack>
            {templateDemo.map((template) => {
              return (
              <TemplateCell template={template} key={template.id} />
              )
            })}
            {templates.map((template) => {
              return (
                <TemplateCell template={template} key={template.id} />
              )
            })}
            <HStack
              bg={'teal20per'}
              borderRadius={'lg'}
              border={'3px solid'}
              borderColor={'transparent'}
              p={2}
              w={'full'}
              _hover={{
                borderColor: 'teal60per',
                bg: 'transparent',
                cursor: 'pointer',
              }}
              justify={'center'}
              onClick={() => setOpenAddTemplate(true)}
            >
              <Text variant={'b2_bold'}>+ Add a template</Text>
            </HStack>
          </VStack>
        </Container>
      </VStack>
      {/* MODALS */}
      <AddTemplate
        isOpen={openAddTemplate}
        onClose={() => {
          setOpenAddTemplate(false)

        }
        }
      />
    </>
  );
}

export default Templates;
