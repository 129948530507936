import { createContext, useContext, useEffect, useState } from 'react';
import { Company } from '../types/company.type';
import { CompanySVC } from '../services/company.service';

type CompanyContextType = {
  company: Company | undefined;
  userCompanies: Company[] | undefined;
  refetchCompany: () => void;
};

export const CompanyContext = createContext<CompanyContextType>({
  company: undefined,
  userCompanies: undefined,
  refetchCompany: () => null,
});

export const CompanyProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  /** ––– How this works –––
   * - user logs in (from user provider)
   * - get all companies of the user
   * - open the last-viewed company
   * - if no company, open the first company
   * - if user navigates to a company, open that company (refetch)
   */

  const [hasFetched, setHasFetched] = useState<boolean>(false);

  const [userCompanies, setUserCompanies] = useState<Company[]>();
  const [viewingCompany, setViewingCompany] = useState<Company>();
  const token = localStorage.getItem('access_token');

  useEffect(() => {
    const fetchCompany = async () => {
      if (!token) return;
      const res = await CompanySVC.getAll(token);
      setUserCompanies(res);
      if (res && res[0]) {
        setViewingCompany(res[0]);
        setHasFetched(true);
      }
    };
    if (!hasFetched && token) fetchCompany();
  }, [hasFetched, token]);

  const refetch = () => {
    setHasFetched(false);
  };

  return (
    <CompanyContext.Provider
      value={{
        company: viewingCompany,
        userCompanies,
        refetchCompany: refetch,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompany = () => useContext(CompanyContext);
