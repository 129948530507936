import { IconProps, Icon } from '@chakra-ui/react';

type DashboardIconProps = {
  color?: string;
  heightInRem?: number;
} & IconProps;

export const DashboardIcon = ({
  color = 'white',
  heightInRem = 1.25,
}: DashboardIconProps) => {
  return (
    <Icon
      fill={color}
      width={`${heightInRem}rem`}
      height={`${heightInRem}rem`}
      viewBox="0 0 40 40"
    >
      <path d="M6 9C6 7.34315 7.34315 6 9 6H16C17.6569 6 19 7.34315 19 9V19C19 20.6569 17.6569 22 16 22H9C7.34315 22 6 20.6569 6 19V9Z" />
      <path d="M6 28C6 26.3431 7.34315 25 9 25H16C17.6569 25 19 26.3431 19 28V32C19 33.6569 17.6569 35 16 35H9C7.34315 35 6 33.6569 6 32V28Z" />
      <path d="M22 9C22 7.34315 23.3431 6 25 6H32C33.6569 6 35 7.34315 35 9V13C35 14.6569 33.6569 16 32 16H25C23.3431 16 22 14.6569 22 13V9Z" />
      <path d="M22 22C22 20.3431 23.3431 19 25 19H32C33.6569 19 35 20.3431 35 22V32C35 33.6569 33.6569 35 32 35H25C23.3431 35 22 33.6569 22 32V22Z" />
    </Icon>
  );
};
