import { ComponentStyleConfig } from '@chakra-ui/react';

export const ModalStyles: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: {
    overlay: {
      bg: 'teal40per', //change the background
      backdropFilter: 'blur(8px)',
    },
    dialog: {
      borderRadius: 'lg',
      bg: `teal60per`,
      backdropFilter: 'blur(16px)',
    },
    header: {
      fontSize: '2xl',
      fontWeight: 800,
      fontFamily: 'heading',
      bg: 'transparent',
    },
    closeButton: {
      size: 'xl',
      marginTop: '0.6rem',
      marginRight: '0.4rem',
    },
  },
};
