import { ComponentStyleConfig } from '@chakra-ui/react';

export const InputStyles: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: {},
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    flushed: {
      field: {
        borderBottom: '2px solid',
        color: 'teal60per',
        borderColor: 'teal40per',
        _placeholder: {
          color: 'white60per',
        },
        _hover: {
          borderColor: 'teal60per',
          _focus: {
            borderColor: 'tealLight',
            color: 'tealLight',
          },
        },
        _focusVisible: {
          color: 'tealLight',
          borderColor: 'tealLight',
          boxShadow: `0px 1px 0px 0px #AFFFFD`,
        },
      },
      addon: {
        borderBottom: '2px solid',
        borderColor: 'pri.purp',
        borderRadius: '0',
        px: '0',
        bg: 'transparent',
      },
    },
    outline: {
      field: {
        color: 'tealLight',
        border: '2px solid',
        borderColor: 'teal40per',
        _placeholder: {
          color: 'white60per',
        },
        _hover: {
          borderColor: 'teal60per',
          _focus: {
            borderColor: 'tealLight',
            color: 'tealLight',
          },
        },
        _focusVisible: {
          color: 'tealLight',
          borderColor: 'tealLight',
          boxShadow: `0px 0px 0px 1px #AFFFFD`,
        },
      },
      addon: {
        borderBottom: '2px solid',
        borderColor: 'green',
        borderRadius: '0',
        px: '0',
        bg: 'transparent',
      },
    },
  },
  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {
    variant: 'outline',
  },
};
