import { ComponentStyleConfig } from '@chakra-ui/react';

export const TagStyles: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: {
    label: {
      fontWeight: 600,
      color: 'white',
    },
  },
  // styles for different sizes ("sm", "md", "lg")
  // sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    green: {
      container: {
        bg: 'green',
        color: 'white',
      },
    },
    violet: {
      container: {
        bg: 'violet',
        color: 'white',
      },
    },
    yellow: {
      container: {
        bg: 'alert',
        color: 'black',
      },
    },
    red: {
      container: {
        bg: 'destructive',
      },
    },
    dark: {
      container: {
        bg: 'teal20per',
      },
    },
  },
};
