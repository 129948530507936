import {
  Container,
  HStack,
  VStack,
  Text,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { LenderSVC } from '../services/lender.service';
import { Lender } from '../types/lender.type';
import AddLender from '../components/modals/addLender.modal';

function Lenders() {
  const [lenders, setLenders] = useState<Lender[]>([]);
  const [openAddLender, setOpenAddLender] = useState(false);
  const token = localStorage.getItem('access_token');

  const fetchLenders = async () => {
    if (token === null) return;
    const res = await LenderSVC.getAll(token);
    if (res?.length) {
      setLenders(res);
    }
  };

  useEffect(() => {
    if (token !== null) {
      fetchLenders();
    }
  }, [token]);

  return (
    <>
      <VStack>
        <Container maxW="860">
          {/* ––– OUTLINE –––
            - totals by stage
            – table of loans (sorted by status)
        */}
          <VStack align={'flex-start'} spacing={4}>
            <HStack w={'full'} justify={'space-between'} pb={4}>
              <Heading variant={'h4'} color={'tealLight'}>
                Lender profiles
              </Heading>
              <Button
                variant={'primary'}
                size={'sm'}
                onClick={() => setOpenAddLender(true)}
              >
                + Add lender profile
              </Button>
            </HStack>
            <TableContainer w={'full'} overflow={'scroll'} pb={12}>
              <Table variant={'simple'}>
                <Thead>
                  <Tr>
                    <Th color={'tealLight'}>Name</Th>
                    <Th color={'tealLight'}>Company</Th>
                    {/* <Th color={'tealLight'}>Location</Th> */}
                    <Th color={'tealLight'}>Email</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {lenders?.length > 0 ? (
                    lenders.map((lender: Lender, index: number) => (
                      <Tr key={index}>
                        <Td>
                          <Text variant={'b1_bold'}>
                            {lender?.first_name} {lender?.last_name}
                          </Text>
                        </Td>
                        <Td>{lender?.company_name}</Td>
                        {/* <Td>{lender?.location?.formatted}</Td> */}
                        <Td>{lender?.email}</Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan={3} textAlign="center">
                        No Records...
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </VStack>
        </Container>
      </VStack>
      {/* MODALS */}
      {openAddLender && (
        <AddLender
          isOpen={openAddLender}
          onClose={() => setOpenAddLender(false)}
          fetchLenders={fetchLenders}
        />
      )}
    </>
  );
}

export default Lenders;
