import { ComponentStyleConfig } from '@chakra-ui/react';

// Body styles applied to the Text component in Chakra-ui

export const TextStyles: ComponentStyleConfig = {
  variants: {
    b1: {
      fontSize: ['1.125rem', '1.25rem'],
      lineHeight: '130%',
      fontWeight: 400,
      color: 'white',
    },
    b1_bold: {
      fontSize: ['1.125rem', '1.25rem'],
      lineHeight: '130%',
      fontWeight: 700,
      color: 'white',
    },
    b2: {
      fontSize: '1.00rem',
      lineHeight: '120%',
      fontWeight: 400,
      color: 'white',
    },
    b2_bold: {
      fontSize: '1.00rem',
      lineHeight: '130%',
      fontWeight: 700,
      color: 'white',
    },
    b2_link: {
      fontSize: '1rem',
      lineHeight: '120%',
      fontWeight: 700,
      textDecoration: 'underline',
      color: 'white',
    },
    b3: {
      fontSize: '0.875rem',
      lineHeight: '130%',
      fontWeight: 400,
      color: 'white',
    },
    b3_bold: {
      fontSize: '0.875rem',
      lineHeight: '130%',
      fontWeight: 700,
      color: 'white',
    },
    b3_link: {
      fontSize: '0.875rem',
      lineHeight: '120%',
      fontWeight: 700,
      textDecoration: 'underline',
      color: 'white',
    },
    b4: {
      fontSize: '0.75rem',
      lineHeight: '120%',
      fontWeight: 400,
      color: 'white',
    },
    b4_bold: {
      fontSize: '0.75rem',
      lineHeight: '120%',
      fontWeight: 700,
      color: 'white',
    },
  },
};
