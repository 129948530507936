import {
  Container,
  HStack,
  VStack,
  Flex,
  Text,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  Tag,
  Box,
} from '@chakra-ui/react';

import { useEffect, useState } from 'react';
import { PropertySVC } from '../services/property.service';
import AddDebtPkgModal from '../components/modals/addDebtPkg.modal';
import { Property } from '../types/property.type';

function Pipeline() {
  const [openAddDebtPkg, setOpenAddDebtPkg] = useState(false);

  const createDebtPackage = () => {
    const token = localStorage.getItem('access_token');
    let url;
    if(!token) {
      url = '';
    } else {
      url = `https://demo.nectorcapital.com/auth?next=/properties/create&token=${token}`;
    }
    window.location.href = url;
  }

  const [properties, setProperties] = useState<Property[]>([]); // TODO: this can't be right
  const token = localStorage.getItem('access_token');

  useEffect(() => {
    const fetchData = async () => {
      const res = await PropertySVC.getAll(token);
      if (res) {
        setProperties(res);
      }
    };
    fetchData();
  }, [token]);

  const sendToLenders = (property: Property) => {
    console.log(property);
    const token = localStorage.getItem('access_token');
    let url;
    if(!token) {
      url = ''
    } else {
      url = `https://demo.nectorcapital.com/auth?next=/properties/${property.id}/package/send&token=${token}`;
      //url = `http://127.0.0.1:8000/auth?next=/properties/${property.id}/package/send&token=${token}`;
    }
    window.location.href = url;
  }

  return (
    <>
      <VStack>
        <Container maxW="860">
          {/* ––– OUTLINE –––
            - totals by stage
            – table of loans (sorted by status)
        */}
          <VStack align={'flex-start'} spacing={4}>
            <HStack w={'full'} justify={'space-between'} pb={4}>
              <Heading variant={'h4'} color={'tealLight'}>
                Debt packages
              </Heading>
              <Button
                variant={'primary'}
                size={'sm'}
                onClick={() => createDebtPackage()}
              >
                + Add debt package
              </Button>
            </HStack>
            <HStack width={'full'}>
              <Flex
                direction={'column'}
                align={'flex-start'}
                flex={1}
                h={'100%'}
                p={4}
                borderRadius={'lg'}
                bg={'teal20per'}
              >
                <Text variant={'b3_bold'} opacity={0.6}>
                  In Progress
                </Text>
                <Box my={1} />
                <Heading variant={'h4'}>{properties.filter(x => x.has_been_sent === false).length}</Heading>
              </Flex>
              <Flex
                direction={'column'}
                align={'flex-start'}
                flex={1}
                h={'100%'}
                p={4}
                borderRadius={'lg'}
                bg={'teal20per'}
              >
                <Text variant={'b3_bold'} opacity={0.6}>
                  Out for Review
                </Text>
                <Box my={1} />
                <Heading variant={'h4'}>{properties.filter(x => x.has_been_sent === true).length}</Heading>
              </Flex>
              <Flex
                direction={'column'}
                align={'flex-start'}
                flex={1}
                h={'100%'}
                p={4}
                borderRadius={'lg'}
                bg={'teal20per'}
              >
                <Text variant={'b3_bold'} opacity={0.6}>
                  Closed
                </Text>
                <Box my={1} />
                <Heading variant={'h4'}>0</Heading>
              </Flex>
              <Flex
                direction={'column'}
                align={'flex-start'}
                flex={1}
                h={'100%'}
                p={4}
                borderRadius={'lg'}
                bg={'teal20per'}
              >
                <Text variant={'b3_bold'} opacity={0.6}>
                  Archived
                </Text>
                <Box my={1} />
                <Heading variant={'h4'}>0</Heading>
              </Flex>
            </HStack>

            <TableContainer w={'full'} overflow={'scroll'} pb={12}>
              <Table variant={'simple'}>
                <Thead>
                  <Tr>
                    <Th color={'tealLight'}>Property</Th>
                    <Th color={'tealLight'} w={240}>
                      Status
                    </Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                {properties.map((property, index) => {
                  return (
                  <Tr>
                    <Td>
                      <Text variant={'b1_bold'}>{property.name}</Text>
                      <Text variant={'b3'} opacity={0.6}>
                        {property.street_address}
                      </Text>
                    </Td>
                    <Td>
                      {property.has_been_sent &&
                        <Tag variant={'green'}>Sent to Lenders</Tag>}

                        {property.has_been_sent === false &&
                      <Tag variant={'yellow'}>In progress</Tag>}
                    </Td>
                    <Td>
                      <Button variant={'primary'} size={'sm'} onClick={() => sendToLenders(property)}>
                        Send to lender
                      </Button>
                    </Td>
                  </Tr>
                  )
                })}
                </Tbody>
              </Table>
            </TableContainer>
          </VStack>
        </Container>
      </VStack>
      {/* MODALS */}
      <AddDebtPkgModal
        isOpen={openAddDebtPkg}
        onClose={() => setOpenAddDebtPkg(false)}
      />
    </>
  );
}

export default Pipeline;
