import { ComponentStyleConfig } from '@chakra-ui/react';

export const MenuStyles: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: {
    list: {
      color: 'violetDark',
    },
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {
    xs: {
      padding: '2px 12px 2px 12px',
    },
    sm: {
      padding: '4px 12px 4px 12px',
      fontSize: '0.875rem',
      borderRadius: '8px',
    },
    md: {
      borderRadius: '12px',
    },
  },
  // styles for different visual variants ("outline", "solid")
};
