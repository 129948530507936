import { ComponentStyleConfig } from '@chakra-ui/react';

export const TableStyles: ComponentStyleConfig = {
  // style object for base or default style
  variants: {
    simple: {
      td: {
        borderColor: 'teal20per',
        borderBottom: '3px solid',
      },
      th: {
        borderColor: 'teal20per',
        borderBottom: '3px solid',
      },
    },
  },
};
