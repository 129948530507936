import {
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Input,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputStepper,
  VStack,
  Select,
  ModalFooter,
  Button,
  Box,
  Flex,
  InputGroup,
  InputLeftElement,
  Text,
  Table,
  Th,
  Thead,
  Tr,
  Tbody,
  Td,
  TableContainer,
  SimpleGrid,
  GridItem,
} from '@chakra-ui/react';
import { SetStateAction, useEffect, useState } from 'react';
import { Place, PropType } from '../../types/property.type';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { LocationIcon } from '../../assets/icons/location.icon';
import { PropertySVC } from '../../services/property.service';
import { TrashIcon } from '../../assets/icons/trash.icon';

type AddPropertyProps = {
  isOpen: boolean;
  onClose: () => void;
};
function AddProperty({ isOpen, onClose }: AddPropertyProps) {
  // manage state for adding a new property
  const [name, setName] = useState<string>();
  const [propType, setPropType] = useState<PropType>('Office'); // default to Office
  const [value, setValue] = useState<string>('100000.00');
  const [sqFt, setSqFt] = useState<string>('1000');
  const [loanAmount, setLoanAmount] = useState<string>('90000.00');
  const [term, setTerm] = useState<string>('180');
  const [amortization, setAmortization] = useState<string>('180');
  const [capRate, setCapRate] = useState<string>('5');
  const [occupancy, setOccupancy] = useState<string>('100');
  const [annualIncome, setAnnualIncome] = useState<string>('1000000.00');
  const [expenses, setExpenses] = useState<string>('1000000.00');
  const [propertyClass, setPropertyClass] = useState<string>('');
  const [owners, setOwners] = useState<{name: string, value: string, percentage: string}[]>([]);
  const [ownerName, setOwnerName] = useState('');
  const [ownerValue, setOwnerValue] = useState('100000.00');
  const [ownerPercentage, setOwnerPercentage] = useState('100');

  function addOwner(name: string, value: string, percentage: string) {
    const newOwner = {
      name,
      value: value,
      percentage: percentage
    };
    setOwners([...owners, newOwner]);
    setOwnerName('');
    setOwnerPercentage('');
  }

  function deleteOwner(name: string) {
    setOwners(owners.filter(owner => owner.name !== name));
  }

  const parse = (val: string) => val.replace(/^\$/, '');
  const format = (val: string) => `$` + val;
  const parsePercent = (val: string) => val.replace(/^\%/, '');
  const formatPercent = (val: string) => val + `%`;
  const PropOptions: PropType[] = [
    'Office',
    'Multi-Family',
    'Retail',
    'Industrial',
    'Hospitality',
    'Mixed-use',
  ];

  const [address, setAddress] = useState<Place>({
    formatted: '',
    lat: 0,
    lng: 0,
    raw: {},
  });

  const [sessionToken, setSessionToken] = useState(
    new google.maps.places.AutocompleteSessionToken()
  );
  const refreshSessionToken = () => {
    setSessionToken(new google.maps.places.AutocompleteSessionToken());
  };

  const searchOptions = {
    fields: ['formatted_address', 'geometry'],
    sessionToken: sessionToken,
  };

  const handleSelectPlace = async (address: string) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setAddress({
        formatted: address,
        lat: latLng.lat,
        lng: latLng.lng,
        raw: results[0],
      });
      refreshSessionToken();
    } catch (err) {
      console.error('Error', err);
    }
  };

  const handleOnChange = (address: string) => {
    setAddress((prevAddress) => ({ ...prevAddress, formatted: address }));
  };

  const addProperty = async () => {
    // pass in all the state values ^ (including raw data in address) to create a new property
    const propertyToCreate = {
      created: new Date(),
      name: name,
      address: address,
      street_address: address.formatted,
      value: parseInt(value),
      value_string: value,
      sqft: parseInt(sqFt),
      property_type: propType,
      loan_amount: parseInt(loanAmount),
      loan_amount_text: loanAmount,
      cap_rate: parsePercent(capRate),
      amortization: parseInt(amortization),
      term: parseInt(term),
      occupancy: parseInt(occupancy),
      property_class: propertyClass,
      projected_annual_income: parseInt(annualIncome),
      expenses: parseInt(expenses),
      owners: owners
    };

    let token = localStorage.getItem('access_token');
    if (!token) return;
    const res = await PropertySVC.create(propertyToCreate, token);
    
    onClose();
    
    // console.log('RESULTS');
    // console.log(res);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 3;

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderPageContent = () => {
    switch (currentPage) {
      case 1:
        return ( 
        <VStack spacing={4}>
          <FormControl label="Property name">
            <FormLabel>Property name</FormLabel>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Type property name ..."
              required
            />
          </FormControl>

          <PlacesAutocomplete
            value={address.formatted}
            onChange={handleOnChange}
            onSelect={handleSelectPlace}
            searchOptions={searchOptions}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <VStack width="full" align="left">
                <FormControl as="button">
                  <FormLabel>Location</FormLabel>
                  <InputGroup variant="outline">
                    <InputLeftElement
                      pointerEvents="none"
                      children={<LocationIcon />}
                    />

                    <Input
                      {...getInputProps({
                        placeholder: 'Type location here...',
                      })}
                    />
                  </InputGroup>
                </FormControl>

                <Flex
                  sx={{
                    paddingTop: '80px',
                    paddingRight: { base: 'non', lg: '20px' },
                    position: 'absolute',
                    overflow: 'auto',
                    display: suggestions.length ? 'block' : 'none',
                    zIndex: 10,
                  }}
                  maxW={{ base: 'sm', md: 'lg' }}
                >
                  <VStack
                    bg="teal"
                    backdropFilter={'blur(12px)'}
                    color="white"
                    align="left"
                    borderRadius="lg"
                    p={2}
                  >
                    {loading ? (
                      <Box p={2}>
                        <Text>Loading...</Text>
                      </Box>
                    ) : null}
                    {suggestions.map((suggestion) => {
                      console.log(suggestion);

                      const style = {
                        backgroundColor: suggestion.active
                          ? 'rgba(6,130,128,0.4)'
                          : 'inherit',
                      };
                      return (
                        <Box
                          {...getSuggestionItemProps(suggestion, {
                            style,
                          })}
                          as="button"
                          borderRadius="md"
                          border="2px solid"
                          borderColor="transparent"
                          display="block"
                          _hover={{
                            borderColor: 'tealLight',
                          }}
                          p={2}
                          textAlign="left"
                        >
                          <Text variant="b2_bold">
                            {suggestion.formattedSuggestion.mainText}{' '}
                            <Text variant="b2" as="span">
                              {suggestion.formattedSuggestion.secondaryText}
                            </Text>
                          </Text>
                        </Box>
                      );
                    })}
                  </VStack>
                </Flex>
              </VStack>
            )}
          </PlacesAutocomplete>
          <FormControl label="Value">
            <FormLabel>Value</FormLabel>
            <NumberInput
              onChange={(valueString) => setValue(parse(valueString))}
              value={format(value)}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>

          <FormControl label="Annual Income">
            <FormLabel>Annual Income</FormLabel>
            <NumberInput
              onChange={(annualIncome) => setAnnualIncome(parse(annualIncome))}
              value={format(annualIncome)}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          <FormControl label="Property type">
            <FormLabel>Property type</FormLabel>
            <Select
              placeholder="Select a type..."
              onChange={(e) => setPropType(e.target.value as PropType)}
            >
              {PropOptions.map((option, i) => {
                return (
                  <option key={i} value={option}>
                    {option}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl label="Square feet">
            <FormLabel>Square feet</FormLabel>
            <NumberInput value={sqFt} onChange={(val) => setSqFt(val)}>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
        </VStack>
        );
      case 2:
        return (
        <VStack spacing={4}>
          <FormControl label="Property class">
            <FormLabel>Property class</FormLabel>
            <Input
              value={propertyClass}
              onChange={(e) => setPropertyClass(e.target.value)}
              placeholder="Type property class: A, B, C etc.,"
              required
            />
          </FormControl>
          <SimpleGrid columns={3} columnGap={1} rowGap={1} w="full">
          <GridItem colSpan={2}>
            <FormControl label="Loan Amount">
              <FormLabel>Loan Amount</FormLabel>
              <NumberInput
                onChange={(loanAmount) => setLoanAmount(parse(loanAmount))}
                value={format(loanAmount)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
          <FormControl label="Cap Rate">
              <FormLabel>Interest Rate</FormLabel>
              <NumberInput
                onChange={(capRate) => setCapRate(parsePercent(capRate))}
                value={formatPercent(capRate)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
          </GridItem>
          </SimpleGrid>
          <FormControl label="Amortization Period">
            <FormLabel>Amortization Period (Months)</FormLabel>
            <NumberInput
              onChange={(amortization) => setAmortization(parse(amortization))}
              value={amortization}
              min={0}
              max={600}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>

          <FormControl label="Loan Term">
            <FormLabel>Loan Term (Months)</FormLabel>
            <NumberInput
              onChange={(term) => setTerm(parse(term))}
              value={term}
              min={0}
              max={600}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>

          <FormControl label="Occupancy">
            <FormLabel>Occupancy</FormLabel>
            <NumberInput
              onChange={(occupancy) => setOccupancy(parsePercent(occupancy))}
              value={formatPercent(occupancy)}
              max={100}
              min={0}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>

          <FormControl label="Expenses">
            <FormLabel>Expenses</FormLabel>
            <NumberInput
              onChange={(expenses) => setExpenses(parse(expenses))}
              value={format(expenses)}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
        </VStack>
        );
      case 3:
       

      return <VStack spacing={4}>
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr opacity={0.7}>
                <Th color='white'>Name</Th>
                <Th color='white'>Value</Th>
                <Th color='white'>Percentage</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {owners.map(owner => (
                <Tr key={owner.name} className="owner-item">
                  <Td>{owner.name}</Td>
                  <Td>{format(owner.value)}</Td>
                  <Td>{owner.percentage}%</Td>
                  <Td>
                    <button onClick={() => deleteOwner(owner.name)}>
                      <TrashIcon />
                    </button>
                  </Td>
                </Tr>
              ))}
            </Tbody>

          </Table>
        </TableContainer>
        <FormControl label="Owner Name">
          <FormLabel>Owner Name</FormLabel>
          <Input
            value={ownerName}
            onChange={(e) => setOwnerName(e.target.value)}
            placeholder="Enter Owner Name"
            required
          />
        </FormControl>
        <FormControl label="Value of Ownership">
          <FormLabel>Value</FormLabel>
          <NumberInput
            onChange={(ownerValue) => setOwnerValue(parse(ownerValue))}
            value={format(ownerValue)}
            min={0}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl label="Percentage of Ownership">
          <FormLabel>Percentage of Ownership</FormLabel>
          <NumberInput
            onChange={(ownerPercentage) => setOwnerPercentage(parsePercent(ownerPercentage))}
            value={formatPercent(ownerPercentage)}
            max={100}
            min={0}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <Button onClick={() => addOwner(ownerName, ownerValue, ownerPercentage)}>Add Owner</Button>
      </VStack>
      default:
        return null;
    }
  };


  const buttons = () => {
    return <ModalFooter>
      { currentPage == 1 ?
        <Button variant="text" mr={3} onClick={onClose}>
          Cancel
        </Button>
        :
        <Button variant="text" mr={3} onClick={prevPage}>
          Previous
        </Button>
      }
      { currentPage == totalPages ?
        <Button variant="teal" onClick={addProperty}>
          Add Property
        </Button>
        :
        <Button variant="teal" onClick={nextPage}>
          Next
        </Button>
      }
    </ModalFooter>
  }


  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Property</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {renderPageContent()}
        </ModalBody>
        {buttons()}
      </ModalContent>
    </Modal>
  );
}

export default AddProperty;
