import {
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  Button,
} from '@chakra-ui/react';

import { PropertySVC } from '../../services/property.service';
import { useNavigate, useParams } from 'react-router-dom';


type DeletePropertyProps = {
  isOpen: boolean;
  onClose: () => void;
};


function DeleteProperty({ isOpen, onClose }: DeletePropertyProps) {

  const { propertyId } = useParams<string>();
  let navigate = useNavigate();

  const deletePropertyConfirm = async () => {
    let token = localStorage.getItem('access_token');
    
    if (!token) return;
    if (!propertyId) return;
  
    const res = await PropertySVC.destroy(propertyId, token);

    navigate("/");
  
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg={'red40per'}>
        <ModalHeader>
          Are you sure you want to delete this property?
        </ModalHeader>
        <ModalFooter>
          <Button variant="text" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button variant="red" onClick={deletePropertyConfirm}>
            Yes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default DeleteProperty;
