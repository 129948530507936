import { ComponentStyleConfig } from '@chakra-ui/react';

// Display and heading styles applied to the Heading component in Chakra-ui

export const HeadingStyles: ComponentStyleConfig = {
  variants: {
    // Heading styles
    h1: {
      fontSize: ['2.25rem', null, '2.75rem', null, null],
      lineHeight: '110%',
      fontWeight: 800,
      color: 'white',
    },
    h2: {
      fontSize: ['1.75rem', null, '2.25rem', null, null],
      lineHeight: '110%',
      fontStyle: 'normal',
      fontWeight: 800,
      color: 'white',
    },
    h3: {
      fontSize: ['1.5rem', null, '1.75rem', null, null],
      lineHeight: ['2rem', null, '2.25rem', null, null],
      fontStyle: 'normal',
      fontWeight: 800,
      color: 'white',
    },
    h4: {
      fontSize: ['1.25rem', null, '1.5rem', null, null],
      lineHeight: '110%',
      fontStyle: 'normal',
      fontWeight: 800,
      color: 'white',
    },
    h5: {
      fontSize: ['1rem', null, '1.25rem', null, null],
      lineHeight: '110%',
      fontStyle: 'normal',
      fontWeight: 800,
      color: 'white',
    },
    h6: {
      fontSize: ['0.875rem', null, '1rem', null, null],
      lineHeight: '110%',
      fontStyle: 'normal',
      fontWeight: 800,
      color: 'white',
    },
  },
  // default values for 'size', 'variant' and 'colorScheme'
};
