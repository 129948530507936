import { IconProps, Icon } from '@chakra-ui/react';

type PipelineIconProps = {
  color?: string;
  heightInRem?: number;
} & IconProps;

export const PipelineIcon = ({
  color = 'white',
  heightInRem = 1.25,
}: PipelineIconProps) => {
  return (
    <Icon
      fill={color}
      width={`${heightInRem}rem`}
      height={`${heightInRem}rem`}
      viewBox="0 0 40 40"
    >
      <path d="M7.72727 31.6667C6.97727 31.6667 6.33523 31.3811 5.80114 30.8099C5.26705 30.2387 5 29.5521 5 28.75V14.1667C5 13.7535 5.13068 13.4071 5.39205 13.1276C5.65341 12.8481 5.97727 12.7083 6.36364 12.7083C6.75 12.7083 7.07386 12.8481 7.33523 13.1276C7.59659 13.4071 7.72727 13.7535 7.72727 14.1667V28.75H29.5455C29.9318 28.75 30.2557 28.8898 30.517 29.1693C30.7784 29.4488 30.9091 29.7951 30.9091 30.2083C30.9091 30.6215 30.7784 30.9679 30.517 31.2474C30.2557 31.5269 29.9318 31.6667 29.5455 31.6667H7.72727ZM13.1818 25.8333C12.4318 25.8333 11.7898 25.5477 11.2557 24.9766C10.7216 24.4054 10.4545 23.7187 10.4545 22.9167V11.25C10.4545 10.4479 10.7216 9.76128 11.2557 9.1901C11.7898 8.61892 12.4318 8.33333 13.1818 8.33333H32.2727C33.0227 8.33333 33.6648 8.61892 34.1989 9.1901C34.733 9.76128 35 10.4479 35 11.25V22.9167C35 23.7187 34.733 24.4054 34.1989 24.9766C33.6648 25.5477 33.0227 25.8333 32.2727 25.8333H13.1818ZM15.9091 22.9167C15.9091 22.1146 15.642 21.4279 15.108 20.8568C14.5739 20.2856 13.9318 20 13.1818 20V22.9167H15.9091ZM29.5455 22.9167H32.2727V20C31.5227 20 30.8807 20.2856 30.3466 20.8568C29.8125 21.4279 29.5455 22.1146 29.5455 22.9167ZM22.7273 21.4583C23.8636 21.4583 24.8295 21.033 25.625 20.1823C26.4205 19.3316 26.8182 18.2986 26.8182 17.0833C26.8182 15.8681 26.4205 14.8351 25.625 13.9844C24.8295 13.1337 23.8636 12.7083 22.7273 12.7083C21.5909 12.7083 20.625 13.1337 19.8295 13.9844C19.0341 14.8351 18.6364 15.8681 18.6364 17.0833C18.6364 18.2986 19.0341 19.3316 19.8295 20.1823C20.625 21.033 21.5909 21.4583 22.7273 21.4583ZM13.1818 14.1667C13.9318 14.1667 14.5739 13.8811 15.108 13.3099C15.642 12.7387 15.9091 12.0521 15.9091 11.25H13.1818V14.1667ZM32.2727 14.1667V11.25H29.5455C29.5455 12.0521 29.8125 12.7387 30.3466 13.3099C30.8807 13.8811 31.5227 14.1667 32.2727 14.1667Z" />
    </Icon>
  );
};
