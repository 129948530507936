import { http } from '../httpCommon';
import { Property } from '../types/property.type';

/** ––– Create Property –––
 * - provide info needed to define a new property (name, type, square feet)
 * - post a new Property into the database
 */
const create = (data: any, token: string) => {
  try {
    return http.post('/properties/', data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    console.log(err);
  }
};

const createBasic = (data: any) => {
  let token = localStorage.getItem('access_token');
  if (!token) return;

  try {
    return http.post('/properties/', data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    console.log(err);
  }
};

/** ––– Get One Property –––
 * - return data of one property to display
 */
const getOne = async (id: string, token: string) => {
  try {
    const res = await http.get(`/properties/${id}/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const resProperty: Property = res.data;
    return resProperty;
  } catch (err) {
    console.log(err);
  }
};

/** ––– Get Properties of a Company –––
 * - return an array of properties related to a company
 */
const getAll = async (token: string | null) => {
  try {
    const res = await http.get(`/properties/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const resProperties: Property[] = res.data;
    return resProperties;
  } catch (err) {
    console.log(err);
  }
};

/** ––– Update Property –––
 * - provide new info to change or add to the existing property object
 * - post changes into the database
 */
const update = async (id: string, data: any, token: string) => {
  try {
    const res = await http.put(`/properties/${id}/`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

const destroy = async(id: string, token: string) => {
  try {
    const res = await http.delete(`/properties/${id}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch(err) {
    console.log(err);
  }
};

/** ––– Get Properties payment shedules –––
 * - return an array of payments related to a property
 */
const getPaymentSchedules = async (sortcode: string, token: string | null) => {
  try {
    const res = await http.get(`/payment-schedule/${sortcode}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const resProperties: any = res.data;
    return resProperties;
  } catch (err) {
    console.log(err);
  }
};

export const PropertySVC = {
  create,
  createBasic,
  getOne,
  getAll,
  update,
  destroy,
  getPaymentSchedules,
};
