import {
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  VStack,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import { useState } from 'react';
import { TemplateSVC } from '../../services/template.service';

type AddTemplateProps = {
  isOpen: boolean;
  onClose: () => void;
};
function AddTemplate({ isOpen, onClose }: AddTemplateProps) {

  const [file, setFile] = useState<File | null>(null);

  // borrowed from: https://uploadcare.com/blog/how-to-upload-file-in-react/
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(e.target.files) {
      setFile(e.target.files[0]);
    }
  }

  const onAddTemplate = async () => {
    if(file) {
      console.log('Uploading template...');

      let formData = new FormData();
      formData.append("file", file);
      console.log(file);
      formData.append("mime_type", file.type);
      formData.append("size_bytes", file.size.toString());
      formData.append("name", file.name);

      try {
        const res = TemplateSVC.create(formData);

        onClose();

      } catch(error) {
        console.error(error);
      }
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Template</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
          <form>
              <input type="file" id="templateFile" onChange={handleFileChange}></input>
          </form>
          {file && (
            <div>
              <p>Name: {file.name}</p>
              <p>Type: {file.type}</p>
              <p>Size: {Math.round(file.size / 1024)}kb</p>
            </div>
          )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="text" mr={3} onClick={onClose}>
            Cancel
          </Button>
          {file && (
          <Button variant="teal" onClick={onAddTemplate}>
            Add
          </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AddTemplate;
