import { http } from '../httpCommon';
import { Company } from '../types/company.type';

/** ––– Create Company –––
 * - provide basic info needed to set up a profile (name, email, password)
 * - post a new Company into the database
 */
const create = (data: Company) => {
  try {
    return http.post('/company/create', data);
  } catch (err) {
    console.log(err);
  }
};

/** ––– Get Companies of a User –––
 * - return company profile data to display if they are authorized
 */
const getAll = async (token: string) => {
  try {
    const res = await http.get(`/company/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const resCompanies: Company[] = res.data;
    return resCompanies;
  } catch (err) {
    console.log(err);
  }
};

/** ––– Update Company –––
 * - provide new info to change or add to the existing company object
 * - post changes into the database
 */
const update = async (data: any, token: string) => {
  try {
    const res = await http.put(`/update-company`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const CompanySVC = {
  create,
  getAll,
  update,
};
