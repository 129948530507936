import {
  Container,
  HStack,
  VStack,
  Text,
  Box,
  Button,
  Img,
  Heading,
  Flex,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Td,
  Th,
  Tr,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import useScrollToTop from '../helpers/scrollToTop.helper';
import { useEffect, useState } from 'react';
import { PropertySVC } from '../services/property.service';
import { Property, DeptPayment, Schedule } from '../types/property.type';
import DeleteProperty from '../components/modals/deleteProperty.modal';


function PropertyPage() {
  const navigate = useNavigate();
  const { propertyId } = useParams<string>();
  const [property, setProperty] = useState<Property>();
  const [deletePropertyOpen, setDeletePropertyOpen] = useState(false);
  // JOHN: the state was wrong here – it was Property[] (array of properties) instead of just one Property
  const [debtPayment, setDeptPayment] = useState<DeptPayment>();
  const token = localStorage.getItem('access_token');

  useEffect(() => {
    console.log('» propertyId', propertyId);
    const fetchProperty = async () => {
      if (token === null || propertyId === undefined) return;
      const res = await PropertySVC.getOne(propertyId, token);
      if (res) {
        // JOHN: need a second condition here before setting the state
        setProperty(res);
        const getDeptDetail = await PropertySVC.getPaymentSchedules(
          res.shortcode,
          token
        );
        setDeptPayment(getDeptDetail);
      }
    };
    if (token !== null && propertyId !== undefined) {
      fetchProperty();
    }
  }, [propertyId, token]);

  useScrollToTop();
  return (
    <>
      <VStack>
        <Container maxW="860">
          <VStack align={'flex-start'}>
            <Box
              as={Button}
              variant={'unstyled'}
              onClick={() => navigate(-1)}
              _hover={{ textDecoration: 'underline' }}
            >
              <Text variant={'b2_bold'}>← Go Back</Text>
            </Box>
            <HStack
              width={'full'}
              spacing={8}
              justify={'space-between'}
              align={'f'}
            >
              <Flex flex={1}>
                <VStack align={'left'} w={'full'}>
                  <Heading variant={'h2'} color={'tealLight'}>
                    {property?.name || 'No property name'}
                    {/* JOHN: here's an example of using the data once it's properly set in state */}
                  </Heading>
                  <Text variant={'b1'}>
                    {property?.city || ''}, {property?.state || ''}
                  </Text>
                  <Box p={4} borderRadius={'lg'} bg={'teal20per'}>
                    <Text variant={'b3_bold'} opacity={0.6}>
                      Total Value
                    </Text>
                    <HStack align={'flex-end'} py={1}>
                      <Heading variant={'h4'}>
                        {property?.value_string || 'n/a'}
                      </Heading>
                      <Text variant={'b2_bold'} color={'tealLight'}>
                        {((property?.percent_of_book || 0) * 100.0).toFixed(2)}%
                        of book
                      </Text>
                    </HStack>
                  </Box>
                  <HStack>
                    <Flex
                      direction={'column'}
                      p={4}
                      flex={2}
                      borderRadius={'lg'}
                      bg={'teal20per'}
                    >
                      <Text variant={'b3_bold'} opacity={0.6}>
                        Total Size
                      </Text>
                      <HStack align={'flex-end'} py={1}>
                        <Heading variant={'h4'}>
                          {property?.sqft || 'n/a'}
                        </Heading>
                        <Text variant={'b2_bold'} color={'tealLight'}>
                          sq. ft
                        </Text>
                      </HStack>
                    </Flex>
                    <Flex
                      direction={'column'}
                      p={4}
                      flex={2}
                      borderRadius={'lg'}
                      bg={'teal20per'}
                    >
                      <Text variant={'b3_bold'} opacity={0.6}>
                        Property Type
                      </Text>
                      <Box py={1}>
                        <Heading variant={'h4'}>Office</Heading>
                      </Box>
                    </Flex>
                  </HStack>
                </VStack>
              </Flex>
              <Img
                src={
                  property?.cover_image ||
                  'https://images.unsplash.com/photo-1448630360428-65456885c650?q=80&w=2967&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                }
                alt={'fake prop img'}
                borderRadius="lg"
                w={360}
                border={'3px solid'}
                borderColor={'teal20per'}
              />
            </HStack>
          </VStack>
          <Box my={16} />
          <VStack align={'flex-start'}>
            <Heading variant={'h4'} color={'tealLight'}>
              Ownership
            </Heading>

            {property?.equity?.map((owner, index) => {
              return (
                <HStack p={4} borderRadius={'lg'} bg={'teal20per'} w={'full'}>
                  <Flex flex={2} align={'center'}>
                    {/* <Avatar
                    size={'sm'}
                    src={
                      'https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                    }
                  /> */}
                    <Box ml={6}>
                      <Text variant={'b1_bold'}>{owner.name}</Text>
                    </Box>
                  </Flex>
                  <Flex flex={2} justify={'flex-end'} align={'center'}>
                    <Box mr={4}>
                      <Text
                        textAlign={'right'}
                        variant={'b2_bold'}
                        opacity={0.6}
                      >
                        valued at ${owner.value}
                      </Text>
                    </Box>
                    <Heading textAlign={'right'} variant={'h4'}>
                      {owner.percentage}%
                    </Heading>
                  </Flex>
                </HStack>
              );
            })}
          </VStack>
          <Box my={16} />
          <VStack align={'flex-start'} spacing={4}>
            <Heading variant={'h4'} color={'tealLight'}>
              Debt details
            </Heading>
            <HStack
              w={'full'}
              align={'flex-start'}
              borderRadius={'lg'}
              bg={'teal20per'}
            >
              <VStack
                align={'flex-start'}
                spacing={1}
                h={'100%'}
                p={4}
                flex={2}
              >
                <Text variant={'b3_bold'} opacity={0.6}>
                  Loan Balance
                </Text>
                <Heading variant={'h4'}>
                  ${debtPayment?.total_cost_of_loan}
                </Heading>
                <Text variant={'b1'} opacity={0.6}>
                  US Bank
                </Text>
              </VStack>
              <Flex
                direction={'column'}
                p={4}
                flex={1}
                borderRadius={'lg'}
                bg={'teal20per'}
              >
                <HStack w={'full'} justify={'space-between'}>
                  <Text variant={'b2_bold'}>Rate</Text>
                  <Text variant={'b2'}>{debtPayment?.interest_rate}%</Text>
                </HStack>
                <HStack w={'full'} justify={'space-between'}>
                  <Text variant={'b2_bold'}>Term</Text>
                  <Text variant={'b2'}>
                    {debtPayment?.no_of_payments} months
                  </Text>
                </HStack>
                <HStack w={'full'} justify={'space-between'}>
                  <Text variant={'b2_bold'}>Amortization</Text>
                  <Text variant={'b2'}>
                    {Number(debtPayment?.no_of_payments || 0) * 2} months
                  </Text>
                </HStack>
                <HStack w={'full'} justify={'space-between'}>
                  <Text variant={'b2_bold'}>Maturity Date</Text>
                  <Text variant={'b2'}>
                    {
                      debtPayment?.schedule?.[debtPayment?.schedule?.length - 1]
                        ?.date
                    }
                  </Text>
                </HStack>
              </Flex>
            </HStack>
            <TableContainer
              w={'full'}
              overflowY={'scroll'}
              overflowX={'scroll'}
              pb={12}
              maxHeight={'400px'}
              sx={{
                '&::-webkit-scrollbar': {
                  height: '0px',
                  width: '7px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#888',
                },
                'td, th': {
                  padding: '10px',
                }
              }}
            >
              <Table variant={'simple'}>
                <Thead>
                  <Tr>
                    <Th color={'tealLight'}>#</Th>
                    <Th color={'tealLight'} w={240}>
                      Date
                    </Th>
                    <Th color={'tealLight'}>Payment</Th>
                    <Th color={'tealLight'}>Start Bal.</Th>
                    <Th color={'tealLight'}>Interest Rate</Th>
                    <Th color={'tealLight'}>Interest Paid</Th>
                    <Th color={'tealLight'}>Principal Paid</Th>
                    <Th color={'tealLight'}>End Bal.</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {debtPayment && debtPayment?.schedule?.length > 0 ? (
                    debtPayment?.schedule.map(
                      (debt: Schedule, index: number) => (
                        <Tr key={index}>
                          <Td>
                            <Text variant={'b1_bold'}>{debt.no}</Text>
                          </Td>
                          <Td>
                            <Text variant={'b2_bold'}>{debt.date}</Text>
                          </Td>
                          <Td>
                            <Text variant={'b2_bold'}>{debt.payment}</Text>
                          </Td>
                          <Td>{debt.start_balance}</Td>
                          <Td>{debtPayment?.interest_rate}%</Td>
                          <Td>{debt.interest_paid}</Td>
                          <Td>{debt.principal_paid}</Td>
                          <Td>{debt.end_balance}</Td>
                        </Tr>
                      )
                    )
                  ) : (
                    <Tr>
                      <Td colSpan={8} textAlign="center">
                        No Records...
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </VStack>
          <Box my={16} />
          <HStack w={'full'} justify={'end'}>
            <Button variant={'red'} onClick={() => setDeletePropertyOpen(true)}>
              Delete Property
            </Button>
          </HStack>

          <Box my={32} />
        </Container>
      </VStack>
      <DeleteProperty
        isOpen={deletePropertyOpen}
        onClose={() => setDeletePropertyOpen(false)}
      />
    </>
  );
}

export default PropertyPage;
