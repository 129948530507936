import {
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  VStack,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { LenderSVC } from '../../services/lender.service';

type AddLenderProps = {
  isOpen: boolean;
  onClose: () => void;
  fetchLenders: () => void;
};
function AddLender({ isOpen, onClose, fetchLenders }: AddLenderProps) {
  const [firstname, setFirstName] = useState<string>('');
  const [lastname, setLastName] = useState<string>('');

  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [institutionName, setInstitutionName] = useState<string>('');
  const token = localStorage.getItem('access_token');
  const onSave = async () => {
    if (!token) {
      setError('Missing authentication token');
      return false;
    }
    if (!institutionName) {
      setError('Institution name is required');
      return false;
    }
    setError('');
    try {
      const res = await LenderSVC.create(
        {
          first_name: firstname,
          last_name: lastname,
          institution_name: institutionName,
          email,
        },
        token
      );
      if (res?.status === 201) {
        fetchLenders && fetchLenders();
        onClose();
      }
    } catch (e: any) {
      const tempError = Object.entries(e?.response?.data || {})?.[0];
      setError(
        `${tempError?.[1]}: ${tempError?.[0]}` ||
          e.message ||
          'Something went wrong'
      );
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Lender</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl label="Lender First Name">
              <FormLabel>Lender First Name</FormLabel>
              <Input
                placeholder="First name"
                value={firstname}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </FormControl>
            <FormControl label="Lender Last Name">
              <FormLabel>Lender Last Name</FormLabel>
              <Input
                placeholder="Last name"
                value={lastname}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </FormControl>
            <FormControl label="Lender name">
              <FormLabel>Email</FormLabel>
              <Input
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </FormControl>
            <FormControl label="Institution name">
              <FormLabel>Institution Name</FormLabel>
              <Input
                placeholder="Institution or bank"
                value={institutionName}
                onChange={(e) => setInstitutionName(e.target.value)}
                required
              />
            </FormControl>
            {error && (
              <Text variant={'b3'} color={'red'}>
                {error}
              </Text>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="text" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button variant="teal" onClick={onSave}>
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AddLender;
