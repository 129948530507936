import {
  Box,
  Container,
  VStack,
  Text,
  Heading,
  HStack,
  Flex,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import AddProperty from '../components/modals/addProperty.modal';
import { useEffect, useState } from 'react';
import { Property } from '../types/property.type';
import { PropertySVC } from '../services/property.service';
import { useCompany } from '../providers/company.provider';
import { Company } from '../types/company.type';
import numberWithCommas from '../helpers/numberWithCommas.helper';

/** ––– Constants –––  */

const TreasuryCarousel = () => {
  const itemWidth = 120; // Width of each item in pixels
  const duplicatedRates = [...trRatesDemo, ...trRatesDemo]; // Duplicate the array

  const totalWidth = duplicatedRates.length * itemWidth; // Total width of all items

  return (
    <Box
      display="flex"
      overflow="hidden"
      width="100%"
      bg={'teal20per'}
      backdropBlur={'8px'}
      py={4}
      borderRadius={'lg'}
    >
      <Box
        display="flex"
        width={`${totalWidth}px`}
        animation={`slide ${trRatesDemo.length * 5}s linear infinite`}
      >
        {duplicatedRates.map((rate, index) => {
          const positive: boolean = rate.change < 0 ? false : true;

          return (
            <Box key={index} flex="none" width={`${itemWidth}px`} mx={6}>
              <HStack w="full" justify={'space-between'} align="flex-end">
                <Box>
                  <Text>{rate.name}</Text>
                  <Heading variant={'h5'}>{rate.value}</Heading>
                </Box>
                <Text
                  align={'right'}
                  variant={'b2_bold'}
                  color={positive ? 'green' : 'destructive'}
                >
                  {positive && '+'}
                  {rate.change}
                </Text>
              </HStack>
            </Box>
          );
        })}
      </Box>

      <style>
        {`
          @keyframes slide {
            0% { transform: translateX(0); }
            100% { transform: translateX(-${totalWidth / 2}px); }
          }
        `}
      </style>
    </Box>
  );
};

const trRatesDemo = [
  {
    name: 'US 1-MO',
    value: 5.381,
    change: -0.002,
  },
  {
    name: 'US 2-MO',
    value: 5.38,
    change: -0.008,
  },
  {
    name: 'US 3-MO',
    value: 5.37,
    change: -0.003,
  },
  {
    name: 'US 4-MO',
    value: 5.367,
    change: 0.002,
  },
  {
    name: 'US 6-MO',
    value: 5.34,
    change: -0.007,
  },
  {
    name: 'US 1-YR',
    value: 4.83,
    change: 0.012,
  },
  {
    name: 'US 2-YR',
    value: 4.275,
    change: 0.003,
  },
  {
    name: 'US 3-YR',
    value: 4.127,
    change: -0.005,
  },
  {
    name: 'US 5-YR',
    value: 4.073,
    change: 0.023,
  },
  {
    name: 'US 7-YR',
    value: 4.143,
    change: 0.038,
  },
  {
    name: 'US 10-YR',
    value: 4.178,
    change: 0.003,
  },
  {
    name: 'US 20-YR',
    value: 4.523,
    change: -0.045,
  },
  {
    name: 'US 30-YR',
    value: 4.406,
    change: 0.003,
  },
];

const mapContainerStyle = {
  width: '360px',
  height: '360px',
  borderRadius: '12px',
  border: '2px solid #000',
};

const center = {
  lat: 40.7128,
  lng: -74.006,
};

// const [markers, setMarkers] = useState([]);

// const markersList = markers.map((marker, index) => {
//   marker.index = index;
//   return (
//     <Marker
//       position={marker.position}
//       name={marker.name}
//   )
// });

const PropertiesMap = () => {
  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={10}
      center={center}
      options={{
        mapId: '7274180531f4476d',
        scaleControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
      }}
    />
  );
};


interface PortfolioStatsProps {
  company: Company;
}

const PortfolioStats = ({ company }: PortfolioStatsProps) => {
  return (
    <Flex flex={1}>
      <VStack align={'left'} w={'full'}>
        <Heading variant={'h4'} color={'tealLight'}>
          Statistics
        </Heading>
        <Box
          w={'full'}
          borderRadius={'lg'}
          p={2}
          bg={'green20per'}
          border={'3px solid'}
          borderColor={'green'}
        >
          <Text variant={'b2'} opacity={0.6}>
            Total Portfolio Value
          </Text>
          <Heading variant={'h2'}>
            ${numberWithCommas(company.portfolio_value || 0)}
          </Heading>
        </Box>
        <HStack w={'full'} align={'flex-start'}>
          <Flex
            direction={'column'}
            flex={2}
            p={2}
            bg={'red20per'}
            borderLeft={'3px solid'}
            borderColor={'destructive'}
          >
            <Box>
              <Text variant={'b3'} opacity={0.6}>
                Total Debt Amount
              </Text>
              <Heading variant={'h4'}>
                ${numberWithCommas(company.total_debt || 0)}
              </Heading>
            </Box>
            <Box pt={2}>
              <Text variant={'b3'} opacity={0.6}>
                Annual Expenses
              </Text>
              <Heading variant={'h4'}>
                $4,550,000
              </Heading>
            </Box>
          </Flex>
          <Flex
            direction={'column'}
            flex={2}
            p={2}
            bg={'violet20per'}
            borderLeft={'3px solid'}
            borderColor={'violet'}
          >
            <Box>
              <Text variant={'b3'} opacity={0.6}>
                Total Equity
              </Text>
              <Heading variant={'h4'}>
                ${numberWithCommas(company.total_equity || 0)}
              </Heading>
            </Box>
          </Flex>
        </HStack>
      </VStack>
    </Flex>
  );
};

const PropertiesTable = ({ company }: { company: Company }) => {
  const navigate = useNavigate();
  const [properties, setProperties] = useState<Property[]>([]); // TODO: this can't be right
  const token = localStorage.getItem('access_token');

  useEffect(() => {
    const fetchData = async () => {
      const res = await PropertySVC.getAll(token);
      if (res) {
        setProperties(res);
      }
    };
    fetchData();
  }, [token]);


  if (properties && properties.length > 0) {
    return (
      <TableContainer w={'full'} overflow={'scroll'} pb={12}>
        <Table variant={'simple'}>
          <Thead>
            <Tr>
              <Th color={'tealLight'} w={400}>
                Name
              </Th>
              <Th color={'tealLight'} w={240}>
                Value
              </Th>
              <Th color={'tealLight'} w={100}>
                % of Book
              </Th>
              <Th color={'tealLight'} w={100}>
                Type
              </Th>
              <Th color={'tealLight'} w={100}>
                Sq. Ft
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {properties.map((property, index) => {

              const percentOfBook = Number(property.percent_of_book).toLocaleString(
                undefined,
                {
                  style: 'percent',
                  minimumFractionDigits: 2,
                }
              );

              return (
                <Tr
                  key={index}
                  onClick={() => navigate(`/property/${property.id}`)}
                  _hover={{
                    bg: 'teal20per',
                    cursor: 'pointer',
                  }}
                >
                  <Td w={400}>
                    <Text variant={'b1_bold'}>{property.name}</Text>
                    <Text variant={'b3'} opacity={0.6}>
                      {property.address.formatted}
                    </Text>
                  </Td>
                  <Td w={240}>
                    <Heading variant={'h5'}>
                      ${numberWithCommas(property.value)}
                    </Heading>
                  </Td>
                  <Td w={100}>
                    <Heading variant={'h5'}>{percentOfBook}</Heading>
                  </Td>
                  <Td w={100}>{property.property_type}</Td>
                  <Td w={100}>{property.sqft}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    );
  } else return <div>no properties found...</div>;
};

/** ––– Dashboard function –––  */
function Dashboard() {
  const [openAddProperty, setOpenAddProperty] = useState(false);
  const { company } = useCompany();

  const closeModal = function () { 
    setOpenAddProperty(false);
    
  }

  return (
    <>
      <VStack>
        <Container maxW="860">
          <VStack spacing={4}>
            <TreasuryCarousel />
            <HStack width={'full'} spacing={8}>
              <Box
                borderRadius={'lg'}
                p={2}
                bg={'teal20per'}
                backdropBlur={'8px'}
              >
                <PropertiesMap />

                <Text variant={'b1_bold'} opacity={0.6} pt={2} pl={1} pb={1}>
                  
                </Text>
              </Box>
              {company && <PortfolioStats company={company} />}
            </HStack>
          </VStack>
          <Box my={16} />
          <VStack>
            <HStack w={'full'} justify={'space-between'} pb={4}>
              <Heading variant={'h4'} color={'tealLight'}>
                Properties
              </Heading>
              <Button
                variant={'primary'}
                size={'sm'}
                onClick={() => setOpenAddProperty(true)}
              >
                + Add property
              </Button>
            </HStack>
            {company && <PropertiesTable company={company} />}
          </VStack>
        </Container>
      </VStack>
      {/* MODALS */}
      <AddProperty
        isOpen={openAddProperty}
        onClose={() => closeModal()}
      />
    </>
  );
}

export default Dashboard;
