import { Outlet } from 'react-router-dom';
import { CompanyProvider } from '../providers/company.provider';
import { UserProvider } from '../providers/user.provider';
import Nav from './nav.component';

function MainLayout() {
  return (
    <UserProvider>
      <CompanyProvider>
        <Nav>
          <Outlet />
        </Nav>
      </CompanyProvider>
    </UserProvider>
  );
}

export default MainLayout;
