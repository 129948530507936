import { http } from '../httpCommon';
import { Template } from '../types/template.type';

/** ––– Create Template –––
 * - provide info needed to define a new template
 * - post a new template into the database
 */
const create = (data: FormData) => {
  let token = localStorage.getItem('access_token');
  if(!token) return;


  console.log('sending');
  console.log(data);
  try {
    return http.post('/templates/', data, {
      headers: { 
        Authorization: `Bearer ${token}`,
        'content-type': 'multipart/form-data',
      }
    });
  } catch (err) {
    console.log(err);
  }
};

/** ––– Get One Template –––
 * - return data of one template to display
 */
const getOne = async (id: string, token: string) => {
  try {
    const res = await http.get(`/${id}/template`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const resTemplate: Template = res.data;
    return resTemplate;
  } catch (err) {
    console.log(err);
  }
};

/** ––– Get Templates of a Company –––
 * - return an array of templates related to a company
 */
const getByCompany = async (token: string) => {
  try {
    const res = await http.get(`/templates/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const resTemplates: Template[] = res.data;
    return resTemplates;
  } catch (err) {
    console.log(err);
  }
};

export const TemplateSVC = {
  create,
  getOne,
  getByCompany,
};
