import { IconProps, Icon } from '@chakra-ui/react';

type TemplatesIconProps = {
  color?: string;
  heightInRem?: number;
} & IconProps;

export const TemplatesIcon = ({
  color = 'white',
  heightInRem = 1.25,
}: TemplatesIconProps) => {
  return (
    <Icon
      fill={color}
      width={`${heightInRem}rem`}
      height={`${heightInRem}rem`}
      viewBox="0 0 40 40"
    >
      <path d="M31.6667 31.6667H13.3333C12.4167 31.6667 11.6319 31.3403 10.9792 30.6875C10.3264 30.0347 9.99999 29.25 9.99999 28.3333V5.00001C9.99999 4.08334 10.3264 3.29862 10.9792 2.64584C11.6319 1.99306 12.4167 1.66667 13.3333 1.66667H23.625C24.0694 1.66667 24.493 1.75001 24.8958 1.91667C25.2986 2.08334 25.6528 2.31945 25.9583 2.62501L34.0417 10.7083C34.3472 11.0139 34.5833 11.3681 34.75 11.7708C34.9167 12.1736 35 12.5972 35 13.0417V28.3333C35 29.25 34.6736 30.0347 34.0208 30.6875C33.368 31.3403 32.5833 31.6667 31.6667 31.6667ZM31.6667 13.3333L23.3333 5.00001V10.8333C23.3333 11.5278 23.5764 12.1181 24.0625 12.6042C24.5486 13.0903 25.1389 13.3333 25.8333 13.3333H31.6667ZM6.66666 38.3333C5.74999 38.3333 4.96527 38.007 4.31249 37.3542C3.65972 36.7014 3.33333 35.9167 3.33333 35V13.3333C3.33333 12.8611 3.49305 12.4653 3.81249 12.1458C4.13194 11.8264 4.52777 11.6667 4.99999 11.6667C5.47222 11.6667 5.86805 11.8264 6.18749 12.1458C6.50694 12.4653 6.66666 12.8611 6.66666 13.3333V35H23.3333C23.8055 35 24.2014 35.1597 24.5208 35.4792C24.8403 35.7986 25 36.1945 25 36.6667C25 37.1389 24.8403 37.5347 24.5208 37.8542C24.2014 38.1736 23.8055 38.3333 23.3333 38.3333H6.66666Z" />
    </Icon>
  );
};
