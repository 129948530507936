import { http } from '../httpCommon';
import { Lender } from '../types/lender.type';

/** ––– Create Lender –––
 * - provide info needed to define a new lender
 * - post a new lender into the database
 */
 const create = (data: Lender, token: string) => {
   try {
     return http.post('/lenders/', data, {
       headers: {
         Authorization: `Bearer ${token}`,
       },
     });
   } catch (err) {
     console.log(err);
   }
 };

/** ––– Get One Lender –––
 * - return data of one lender to display
 */
const getOne = async (id: string, token: string) => {
  try {
    const res = await http.get(`/${id}/lender`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const resLender: Lender = res.data;
    return resLender;
  } catch (err) {
    console.log(err);
  }
};

/** ––– Get Lenders of a Company –––
 * - return an array of lenders related to a company
 */
const getAll = async (token: string) => {
  try {
    const res = await http.get(`/lenders/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const resLenders: Lender[] = res.data;
    return resLenders;
  } catch (err) {
    console.log(err);
  }
};

export const LenderSVC = {
  create,
  getOne,
  getAll,
};
