import { ComponentStyleConfig } from '@chakra-ui/react';

export const NumberInputStyles: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: {},
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    outline: {
      field: {
        color: 'white',
        border: '2px solid',
        borderColor: 'teal40per',
        _placeholder: {
          color: 'white60per',
        },
        _hover: {
          borderColor: 'teal60per',
          _focus: {
            borderColor: 'tealLight',
            color: 'tealLight',
          },
        },
        _focusVisible: {
          color: 'tealLight',
          borderColor: 'tealLight',
          boxShadow: `0px 0px 0px 1px #AFFFFD`,
        },
      },
      stepper: {
        color: 'white',
        border: 'none',
        _hover: {
          bg: 'teal40per',
        },
        _active: {
          bg: 'teal60per',
        },
      },
      stepperGroup: {
        borderColor: 'teal40per',
      },
    },
  },
  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {
    variant: 'outline',
  },
};
