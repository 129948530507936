import { IconProps, Icon } from '@chakra-ui/react';

type DropdownIconProps = {
  color?: string;
  heightInRem?: number;
} & IconProps;

export const DropdownIcon = ({
  color = 'white',
  heightInRem = 1.25,
}: DropdownIconProps) => {
  return (
    <Icon
      fill={color}
      width={`${heightInRem}rem`}
      height={`${heightInRem}rem`}
      viewBox="0 0 40 40"
    >
      <path d="M29.1029 14.7302C29.3529 14.9802 29.4849 15.2719 29.4988 15.6052C29.5127 15.9386 29.3946 16.2302 29.1446 16.4802L20.8946 24.7302C20.7557 24.8691 20.6168 24.9663 20.4779 25.0219C20.3391 25.0774 20.1863 25.1052 20.0196 25.1052C19.8529 25.1052 19.7002 25.0774 19.5613 25.0219C19.4224 24.9663 19.2835 24.8691 19.1446 24.7302L10.8946 16.5219C10.6446 16.2997 10.5196 16.0149 10.5196 15.6677C10.5196 15.3205 10.6446 15.0219 10.8946 14.7719C11.1446 14.5219 11.4432 14.3969 11.7905 14.3969C12.1377 14.3969 12.4363 14.5219 12.6863 14.7719L20.0196 22.0636L27.3529 14.6886C27.5752 14.4386 27.8599 14.3205 28.2071 14.3344C28.5543 14.3483 28.8529 14.4802 29.1029 14.7302Z" />
    </Icon>
  );
};
