import { Th, Tr, extendTheme } from '@chakra-ui/react';
import { colors } from './colors.theme';
import { fonts } from './fonts.theme';
import { TextStyles as Text } from './styles/text.style';
import { HeadingStyles as Heading } from './styles/heading.style';
import { ButtonStyles as Button } from './styles/button.style';
import { TableStyles as Table } from './styles/table.style';
import { MenuStyles as Menu } from './styles/menu.style';
import { TagStyles as Tag } from './styles/tag.style';
import { ModalStyles as Modal } from './styles/modal.style';
import { InputStyles as Input } from './styles/input.style';
import { NumberInputStyles as NumberInput } from './styles/numberInput.style';
import { SelectStyles as Select } from './styles/select.style';

export const theme = extendTheme({
  fonts,
  colors,
  components: {
    Text,
    Heading,
    Button,
    Table,
    Menu,
    Tag,
    Modal,
    Input,
    NumberInput,
    Select,
  },
  styles: {
    global: {
      body: {
        color: 'white',
        bg: 'linear-gradient(103deg, #073B3A 8.46%, #03030A 73.12%, #000 100%)',
      },
      th: {
        color: 'white',
      }
    },
  },
});
