import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function AuthRedirect() {
  const navigate = useNavigate();
  const query = useQuery();

  useEffect(() => {
    const accessToken = query.get('access_token');
    const refreshToken = query.get('refresh_token');

    if (accessToken && refreshToken) {
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('refresh_token', refreshToken);
      navigate('/');
    }
  }, [query, navigate]);

  return null;
}

export default AuthRedirect;
