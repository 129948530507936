// Import the react JS packages
// @ts-ignore
import logo from '../assets/nectorLogo.png';
import {
  Container,
  Heading,
  Img,
  VStack,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useState } from 'react';
import { http } from '../httpCommon';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// Define the Login function.
export const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleClick = () => setShow(!show);

  const submitLogin = async () => {
    console.log('» submit login');
    if (!username || !password) {
      setError('Please enter username and password');
      return;
    }
    const user = {
      username,
      password,
    };
    // get token via POST request
    const response = await http.post('/token/', user).catch((err) => {
      console.error(err);
      setError('Invalid username or password');
    });
    if (!response) return;
    
    const data = response.data; // Add type assertion to ensure 'data' property exists
    console.log('» response', data);
    // initalize tokens in local storage
    localStorage.clear();
    localStorage.setItem('access_token', data.access);
    localStorage.setItem('refresh_token', data.refresh);
    axios.defaults.headers.common['Authorization'] = `Bearer ${data['access']}`;
    // redirect to home page
    navigate('/');
  };

  return (
    <VStack
      minH={'100vh'}
      bg={'linear-gradient(103deg, #073B3A 8.46%, #03030A 73.12%, #000 100%)'}
      justify={'center'}
    >
      <Container maxW={'md'}>
        <VStack
          bg={'teal20per'}
          borderRadius={'xl'}
          backdropBlur={8}
          p={8}
          spacing={8}
        >
          <Img src={logo} alt={'nector logo'} w={'120px'} />
          <VStack w={'full'}>
            <Heading variant={'h2'} color={'tealLight'}>
              Welcome back
            </Heading>

            <Text variant={'b2'} color={'white60per'}>
              Enter your username and password to{' '}
              <Text variant={'b2_bold'} as={'span'}>
                login.
              </Text>
            </Text>
          </VStack>

          <VStack w={'full'}>
            <FormControl id="username">
              <FormLabel variant={'b3'} color={'white60per'}>
                Username
              </FormLabel>
              <Input
                placeholder={'Type username ...'}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel variant={'b3'} color={'white60per'}>
                Password
              </FormLabel>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  type={show ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Type password ..."
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="xs"
                    variant={'teal'}
                    onClick={handleClick}
                  >
                    {show ? 'Hide' : 'Show'}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </VStack>
          <VStack w={'full'} spacing={1}>
            <Text variant={'b3'} color={'red'}>
              {error}
            </Text>
            <Button variant={'teal'} w={'full'} onClick={() => submitLogin()}>
              Login
            </Button>
            <Button
              variant={'text'}
              w={'full'}
              onClick={() => navigate('/signup')}
            >
              Sign up
            </Button>
          </VStack>
        </VStack>
      </Container>
    </VStack>
  );
};
