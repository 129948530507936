export const colors: {
  [key: string]: string;
} = {
  // Primary
  green: '#157F1F',
  violet: '#2D2D99',
  teal: '#068280',

  // Semantic
  alert: '#FFBA08',
  destructive: '#C81D25',

  // Typeface
  tealLight: '#AFFFFD',

  // Background
  greenDark: '#073B3A',
  violetDark: '#03030A',
  teal20per: 'rgba(6,130,128,0.2)',
  teal40per: 'rgba(6,130,128,0.4)',
  teal60per: 'rgba(6,130,128,0.6)',
  green20per: 'rgba(21,127,31,0.2)',
  green40per: 'rgba(21,127,31,0.4)',
  green60per: 'rgba(21,127,31,0.6)',
  red20per: 'rgba(200,29,37,0.2)',
  red40per: 'rgba(200,29,37,0.4)',
  red60per: 'rgba(200,29,37,0.6)',
  violet20per: 'rgba(45,45,153,0.2)',
  violet60per: 'rgba(45,45,153,0.6)',
  darkViolet60per: 'rgba(3,3,10,0.6)',
  white60per: 'rgba(255,255,255,0.6)',
};
