import { ComponentStyleConfig } from '@chakra-ui/react';

export const ButtonStyles: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: {
    _disabled: {
      opacity: 1,
      cursor: 'auto',
      boxShadow: 'none',
    },
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {
    xs: {
      padding: '2px 12px 2px 12px',
    },
    sm: {
      padding: '4px 12px 4px 12px',
      fontSize: '0.875rem',
      borderRadius: '8px',
    },
    md: {
      borderRadius: '12px',
    },
  },
  // styles for different visual variants ("outline", "solid")
  variants: {
    primary: {
      bg: 'violet',
      color: 'white',
      border: '3px solid',
      borderColor: 'transparent',
      _hover: {
        bg: 'violet60per',
        borderColor: 'violet',
      },
      _loading: {
        bg: 'violetDark',
        opacity: 1,
        borderColor: 'violet',
        _hover: {
          bg: 'violetDark',
          opacity: 1,
          borderColor: 'violet',
        },
      },
    },
    teal: {
      bg: 'teal60per',
      color: 'white',
      border: '3px solid',
      borderColor: 'transparent',
      _hover: {
        bg: 'teal40per',
        borderColor: 'teal',
      },
      _loading: {
        bg: 'teal40per',
        opacity: 1,
        borderColor: 'teal',
        _hover: {
          bg: 'teal40per',
          opacity: 1,
          borderColor: 'teal',
        },
      },
    },
    red: {
      bg: 'red60per',
      color: 'white',
      border: '3px solid',
      borderColor: 'transparent',
      _hover: {
        bg: 'red40per',
        borderColor: 'red',
      },
      _loading: {
        bg: 'red40per',
        opacity: 1,
        borderColor: 'red',
        _hover: {
          bg: 'red40per',
          opacity: 1,
          borderColor: 'red',
        },
      },
    },
    text: {
      bg: 'transparent',
      color: 'white60per',
      _hover: {
        bg: 'teal20per',
        borderColor: 'teal',
        color: 'white',
        textDecoration: 'underline',
      },
    },
    sideNavMenu: {
      bg: 'teal20per',
      color: 'white',
      fontFamily: 'Inter, sans-serif',
      fontWeight: 800,
      flexWrap: 'wrap',
      width: 'full',
      align: 'left',
      fontSize: ['1rem', null, '1.25rem', null, null],
      border: '3px solid',
      borderColor: 'transparent',
      _hover: {
        bg: 'transparent',
        borderColor: 'teal20per',
      },
    },
  },
};
