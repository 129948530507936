import { IconProps, Icon } from '@chakra-ui/react';

type LocationIconProps = {
  color?: string;
  heightInRem?: number;
} & IconProps;

export const LocationIcon = ({
  color = 'white',
  heightInRem = 1.25,
}: LocationIconProps) => {
  return (
    <Icon
      fill={color}
      width={`${heightInRem}rem`}
      height={`${heightInRem}rem`}
      viewBox="0 0 40 40"
    >
      <path d="M19.9998 3.33337C12.9998 3.33337 6.6665 8.70004 6.6665 17C6.6665 22.3 10.7498 28.5334 18.8998 35.7167C19.5332 36.2667 20.4832 36.2667 21.1165 35.7167C29.2498 28.5334 33.3332 22.3 33.3332 17C33.3332 8.70004 26.9998 3.33337 19.9998 3.33337ZM19.9998 20C18.1665 20 16.6665 18.5 16.6665 16.6667C16.6665 14.8334 18.1665 13.3334 19.9998 13.3334C21.8332 13.3334 23.3332 14.8334 23.3332 16.6667C23.3332 18.5 21.8332 20 19.9998 20Z" />
    </Icon>
  );
};
