// Import the react JS packages
// @ts-ignore
import logo from '../assets/nectorLogo.png';
import {
  Container,
  Heading,
  Img,
  VStack,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useState } from 'react';
import { http } from '../httpCommon';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// Define the Signup function.
export const Signup = () => {
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleClick = () => setShow(!show);

  const submitSignup = async () => {
    console.log('» submit Signup');
    if (!email || !password || !firstName || !lastName || !companyName) {
      setError('Please complete all fields');
      return;
    }
    const payload = {
      email,
      password,
      first_name: firstName,
      last_name: lastName,
      company_name: companyName,
    };
    console.log('» payload', payload);
    const response = await http.post('/register/', payload).catch((err) => {
      console.error(err);
      setError('Had trouble signing you up. Please try again.');
    });
    if(!response) return;

    // Grab out the data
    const data = response.data;
    
    // Clear local storage and set the token and the refresh and the auth header.
    localStorage.clear();
    localStorage.setItem('access_token', data.access);
    localStorage.setItem('refresh_token', data.refresh);
    axios.defaults.headers.common['Authorization'] = `Bearer ${data['access']}`;

    // All right, let's go to the right place.
    navigate('/');

  };

  return (
    <VStack
      minH={'100vh'}
      bg={'linear-gradient(103deg, #073B3A 8.46%, #03030A 73.12%, #000 100%)'}
      justify={'center'}
    >
      <Container maxW={'md'}>
        <VStack
          bg={'teal20per'}
          borderRadius={'xl'}
          backdropBlur={8}
          p={8}
          spacing={8}
        >
          <Img src={logo} alt={'nector logo'} w={'120px'} />
          <VStack w={'full'}>
            <Heading variant={'h2'} color={'tealLight'}>
              Join Nector
            </Heading>

            <Text variant={'b2'} color={'white60per'}>
              Fill this form to create a free accounts.
            </Text>
          </VStack>

          <VStack w={'full'}>
            <FormControl id="email">
              <FormLabel variant={'b3'} color={'white60per'}>
                Email
              </FormLabel>
              <Input
                placeholder={'Type email ...'}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl id="firstName">
              <FormLabel variant={'b3'} color={'white60per'}>
                First Name
              </FormLabel>
              <Input
                placeholder={'Type first name ...'}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </FormControl>
            <FormControl id="lastName">
              <FormLabel variant={'b3'} color={'white60per'}>
                Last Name
              </FormLabel>
              <Input
                placeholder={'Type last name ...'}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </FormControl>
            <FormControl id="companyName">
              <FormLabel variant={'b3'} color={'white60per'}>
                Company Name
              </FormLabel>
              <Input
                placeholder={'Type company name...'}
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                />
            </FormControl>
            <FormControl id="password">
              <FormLabel variant={'b3'} color={'white60per'}>
                Password
              </FormLabel>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  type={show ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Type password ..."
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="xs"
                    variant={'teal'}
                    onClick={handleClick}
                  >
                    {show ? 'Hide' : 'Show'}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </VStack>
          <VStack w={'full'} spacing={1}>
            <Text variant={'b3'} color={'red'}>
              {error}
            </Text>
            <Button variant={'teal'} w={'full'} onClick={() => submitSignup()}>
              Sign up
            </Button>
            <Button
              variant={'text'}
              w={'full'}
              onClick={() => navigate('/login')}
            >
              Log in
            </Button>
          </VStack>
        </VStack>
      </Container>
    </VStack>
  );
};
