import { IconProps, Icon } from '@chakra-ui/react';

type MenuIconProps = {
  color?: string;
  heightInRem?: number;
} & IconProps;

export const MenuIcon = ({
  color = 'white',
  heightInRem = 1.25,
}: MenuIconProps) => {
  return (
    <Icon
      fill={color}
      width={`${heightInRem}rem`}
      height={`${heightInRem}rem`}
      viewBox="0 0 40 40"
    >
      <path d="M6.25 15C5.88889 15 5.59028 14.8819 5.35417 14.6458C5.11806 14.4097 5 14.1111 5 13.75C5 13.3889 5.11806 13.0903 5.35417 12.8542C5.59028 12.6181 5.88889 12.5 6.25 12.5H33.75C34.1111 12.5 34.4097 12.6181 34.6458 12.8542C34.8819 13.0903 35 13.3889 35 13.75C35 14.1111 34.8819 14.4097 34.6458 14.6458C34.4097 14.8819 34.1111 15 33.75 15H6.25Z" />
      <path d="M5.35417 26.3125C5.59028 26.5486 5.88889 26.6667 6.25 26.6667H33.75C34.1111 26.6667 34.4097 26.5486 34.6458 26.3125C34.8819 26.0764 35 25.7778 35 25.4167C35 25.0555 34.8819 24.7569 34.6458 24.5208C34.4097 24.2847 34.1111 24.1667 33.75 24.1667H6.25C5.88889 24.1667 5.59028 24.2847 5.35417 24.5208C5.11806 24.7569 5 25.0555 5 25.4167C5 25.7778 5.11806 26.0764 5.35417 26.3125Z" />
    </Icon>
  );
};
