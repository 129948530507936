import {
  Button,
  Container,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  VStack,
  Text,
  Divider,
} from '@chakra-ui/react';

import { useEffect, useState } from 'react';
import ImageUpload from '../components/imageUpload.component';
import { UserSVC } from '../services/user.service';

function Settings() {
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [companyName, setCompanyName] = useState<string>();
  const [show, setShow] = useState(false);
  const [changesSaved, setChangesSaved] = useState(false);

  const token = localStorage.getItem('access_token');

  const handleClick = () => setShow(!show);

  useEffect(() => {
    const fetchUser = async () => {
      const res = await UserSVC.getOne(token);
      console.log(res);
      if(res !== undefined) {
        setName(res.first_name + ' ' + res.last_name);
        setEmail(res.email);
        if(res.companies && res.companies.length > 0) {
          setCompanyName(res.companies[0].role);
        }

      }
    }
    if(token !== null) {
      fetchUser();
    }
  }, [token] 
);
  
  const handleSave = async () => {
    // update the user or company with state values

    // show the changes saved message
    setChangesSaved(true);
  };

  return (
    <>
      <VStack>
        <Container maxW="860">
          <VStack spacing={4} align="flex-start" pb={8}>
            <ImageUpload />
            <FormControl label="Name">
              <FormLabel variant={'b3'} color={'white60per'}>
                Name
              </FormLabel>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Type full name ..."
                required
              />
            </FormControl>

            <FormControl label="Email">
              <FormLabel variant={'b3'} color={'white60per'}>
                Email
              </FormLabel>
              <Input
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Type email ..."
                required
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel variant={'b3'} color={'white60per'}>
                Password
              </FormLabel>

              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  type={show ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Type password ..."
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="xs"
                    variant={'teal'}
                    onClick={handleClick}
                  >
                    {show ? 'Hide' : 'Show'}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <FormControl label="Company name">
              <FormLabel variant={'b3'} color={'white60per'}>
                Company name
              </FormLabel>
              <Input
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                placeholder="Type company name ..."
                required
              />
            </FormControl>
          </VStack>
          <Divider borderColor="teal60per" />
          <HStack py={8} spacing={4}>
            <Button variant="teal" onClick={handleSave}>
              Save
            </Button>
            {changesSaved && <Text color="white60per">All changes saved</Text>}
          </HStack>
        </Container>
      </VStack>
    </>
  );
}

export default Settings;
